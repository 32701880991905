import { Outlet, RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { AmplitudeProvider } from 'providers/AmplitudeProvider';
import { RootStoreProvider } from 'providers/RootStoreProvider';
import AuthCheck from 'components/AuthCheck';
import ScrollToTop from 'components/ScrollToTop';
import ScrollToHash from 'components/ScrollToHash';
import route from 'constants/routes';
import { isProductionEnv } from 'helpers/environment';
import theme from './theme';
import { setLanguage } from 'helpers/language.helpers';
import { sentryCreateBrowserRouter } from 'services/sentry/sentry.router';
import { DirectionWrapper } from 'providers/wrappers';
import { DevToolsWidget } from 'components/DevToolsWidget';

const RootLayout = () => {
    setLanguage();

    return (
        <RootStoreProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthCheck>
                    <AmplitudeProvider>
                        <ScrollToTop />
                        <ScrollToHash />
                        <DirectionWrapper>
                            <Outlet />
                        </DirectionWrapper>
                        {!isProductionEnv && <DevToolsWidget />}
                    </AmplitudeProvider>
                </AuthCheck>
            </ThemeProvider>
        </RootStoreProvider>
    );
};

const router = sentryCreateBrowserRouter([
    {
        element: <RootLayout />,
        children: [...route],
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
