import { action, makeObservable, observable } from 'mobx';
import { CountryCode } from 'libphonenumber-js';
import { CarrierData, getPhoneCarrier } from 'http-client/phone.client';
import { RootStore } from './root.store';
import { loadFromStorage, saveToStorage } from 'services/storage';

interface IpInfo {
    ip: string;
    city: string;
    country: CountryCode;
    postal: string;
    loc: string;
    timezone: string;
}

const flowOuterStorageItem = localStorage.getItem('flowOuter');
export class AppStore {
    rootStore: RootStore;
    amplitudeUserId = localStorage.getItem('amplitudeUserId') || '';
    searchPhoneNumber = sessionStorage.getItem('searchPhoneNumber') || '';
    searchCountryCode = '';
    searchCarrierData: CarrierData | null = null;
    ipInfo: IpInfo | null = null;
    abTestData: Record<string, any> | null = loadFromStorage('abTestData') || null;
    fakePhoneLocated = false;
    isPaymentMapFormShown = false;
    flowOuter = flowOuterStorageItem ? !!JSON.parse(flowOuterStorageItem) : false;
    loading = false;
    ampliIDLoading = true;
    isAccountRedirectDisabled = true;
    isAttributionSent = loadFromStorage('isAttributionSent') ?? false;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            ipInfo: observable,
            searchPhoneNumber: observable,
            searchCarrierData: observable,
            amplitudeUserId: observable,
            loading: observable,
            ampliIDLoading: observable,
            fakePhoneLocated: observable,
            abTestData: observable,
            flowOuter: observable,
            isAccountRedirectDisabled: observable,
            isPaymentMapFormShown: observable,
            isAttributionSent: observable,
            setIsAttributionSent: action,
            setIpInfo: action,
            setSearchPhoneNumber: action,
            setPhoneCarrier: action,
            setAmplitudeUserId: action,
            setLoading: action,
            setAmpliIDLoading: action,
            setFlowOuter: action,
            setAbTestData: action,
            setIsPaymentMapFormShown: action,
        });
        this.rootStore = rootStore;
    }

    setIsAttributionSent = (isSent: boolean) => {
        this.isAttributionSent = isSent;
        saveToStorage('isAttributionSent', isSent);
    };

    setIpInfo = (ipInfo: any) => {
        this.ipInfo = ipInfo;
    };

    setSearchPhoneNumber = (searchPhoneNumber: string) => {
        sessionStorage.setItem('searchPhoneNumber', searchPhoneNumber);
        this.searchPhoneNumber = searchPhoneNumber;
    };

    setSearchCountryCode = (searchCountryCode: string) => {
        this.searchCountryCode = searchCountryCode;
    };

    setPhoneCarrier = (carrierData: CarrierData) => {
        this.searchCarrierData = carrierData;
    };

    setIsAccountRedirectDisabled = (isDisabled: boolean) => {
        this.isAccountRedirectDisabled = isDisabled;
    };

    fetchPhoneCarrier = async () => {
        const data = await getPhoneCarrier(this.searchPhoneNumber);
        if (data) {
            this.setPhoneCarrier(data);
        }
        return data;
    };

    setFakePhoneLocated = (fakePhoneLocated: boolean) => {
        this.fakePhoneLocated = fakePhoneLocated;
    };

    setAbTestData = (abTestData: Record<string, any> | null) => {
        this.abTestData = abTestData;
        saveToStorage('abTestData', JSON.stringify(abTestData));
    };

    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    setAmpliIDLoading = (loading: boolean) => {
        this.ampliIDLoading = loading;
    };

    setFlowOuter = (flowOuter: boolean) => {
        if (typeof flowOuter !== 'boolean') {
            throw new TypeError('flowOuter must be a boolean');
        }
        this.flowOuter = flowOuter;
        localStorage.setItem('flowOuter', JSON.stringify(flowOuter));
    };

    setAmplitudeUserId = (userId: string) => {
        localStorage.setItem('amplitudeUserId', userId);
        this.amplitudeUserId = userId;
    };

    setIsPaymentMapFormShown = (isShown: boolean) => {
        this.isPaymentMapFormShown = isShown;
    };

    hydrate = (data: any) => {
        if (!data) {
            return;
        }
        if (data.searchPhoneNumber) {
            this.searchPhoneNumber = data.searchPhoneNumber;
        }
    };
}
