import { FC, PropsWithChildren, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { getIpInfo } from 'http-client/ip-info';
import { AppRoutes } from 'enums/routes.enum';
import { useAppData } from 'providers/RootStoreProvider';
import { RESTRICTED_COUNTRIES } from 'constants/restrictedCountries';

export const IpBasedAccessControl: FC<PropsWithChildren> = observer(({ children }) => {
    const navigate = useNavigate();
    const { flowOuter, ipInfo, setIpInfo } = useAppData();

    useLayoutEffect(() => {
        if (!ipInfo) {
            (async () => {
                const ip = await getIpInfo(flowOuter);
                setIpInfo(ip);
            })();
        } else if (RESTRICTED_COUNTRIES.includes(ipInfo?.country)) {
            navigate(AppRoutes.RESTRICTED_ACCESS);
        }
    }, [ipInfo]);

    return <>{children}</>;
});
