import React, { useEffect, useLayoutEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { generateId } from '@kissmyapps-tlm/client-web-attribution';

import { useAppData, useUserStore } from 'providers/RootStoreProvider';
import { getAllSearchParams } from 'helpers/getAllSearchParams';
import { ampli } from 'services/ampli';
import { saveToStorage } from 'services/storage';
import { AppRoutes } from 'enums/routes.enum';
import { updateWebSession } from 'http-client/session.client';
import { useDetectAttributionSentState, useAbTestData } from './hooks';
import { IpBasedAccessControl } from './wrappers';
import { useBoolean } from 'hooks/useBoolean';
import { loadAmpli, webAttribution } from 'services/attributionConfig';

export const AmplitudeProvider = observer(({ children }: React.PropsWithChildren) => {
    const {
        amplitudeUserId,
        setAmplitudeUserId,
        setAmpliIDLoading,
        flowOuter,
        setFlowOuter,
        abTestData: sessionAbTestData,
        setAbTestData: setSessionAbTestData,
        isAttributionSent,
        setIsAttributionSent,
    } = useAppData();
    const { user, userProfile, userWebSession, setWebSessionLoading } = useUserStore();
    const { abTestData, allAbTestFlags } = useAbTestData();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();
    const [searchParams] = useSearchParams();
    const [isAmpliLoaded, setIsAmpliLoaded] = useBoolean();
    const detectAttributionSentState = useDetectAttributionSentState();

    const allQueryParams = getAllSearchParams(searchParams);
    const {
        usid: usidQueryParam,
        flowOuter: flowOuterQueryParam,
        gclid: gclidQueryParam,
        is_test: isTestQueryParam,
    } = allQueryParams as Record<string, string>;

    const usid = userProfile?.amplitudeSessionId || amplitudeUserId || usidQueryParam || generateId();

    const saveAttributionSentState = async () => {
        if (user) {
            await updateWebSession({ ...userWebSession, webAttributionSent: true });
        }
        localStorage.setItem('attributionSentId', usid);
        return;
    };

    useEffect(() => {
        setIsAttributionSent(detectAttributionSentState(usid));
    }, [userProfile, isAmpliLoaded, amplitudeUserId]);

    useEffect(() => {
        if (userWebSession?.session?.flowOuter) {
            setFlowOuter(userWebSession.session.flowOuter);
        }
    }, [amplitudeUserId]);

    useEffect(() => {
        if (isTestQueryParam) {
            sessionStorage.setItem('isTestUser', isTestQueryParam);
        }
    }, [isTestQueryParam]);

    useEffect(() => {
        (async () => {
            if (!abTestData) return;

            try {
                if (isAttributionSent) {
                    await loadAmpli();
                    await ampli.identify(amplitudeUserId);
                } else if (ampli && amplitudeUserId && !isAttributionSent && abTestData) {
                    await loadAmpli();
                    await webAttribution({ amplitudeUserId, abTests: allAbTestFlags });
                    await saveAttributionSentState();
                    setIsAmpliLoaded.on();
                }

                setAmplitudeUserId(usid);
                setWebSessionLoading(false);
            } catch (err) {
                console.error('Failed to provide Amplitude identification');
            }
        })();
    }, [userProfile?.amplitudeSessionId, amplitudeUserId, abTestData]);

    useLayoutEffect(() => {
        /*
         * Saving into store and removing 'flowOuter' search
         * parameter from browser's URL bar on initial visit
         */
        if (!flowOuter && flowOuterQueryParam === 'true') {
            setFlowOuter(true);
        }

        if (gclidQueryParam) {
            saveToStorage('gclid', gclidQueryParam);
        }

        const isLegalPages = [AppRoutes.TERMS_OF_USE, AppRoutes.PRIVACY_POLICY, AppRoutes.COOKIE_POLICY].includes(pathname as AppRoutes);

        /*
         *  Here we are visually updating 'usid' search param (usidQueryParam) in
         *  browsers URL bar when page navigation happens or if amplitudeUserId has changed.
         *  With exception of this AmplitudeProvider module, 'usid' search param should not be used
         *  as "source of truth" elsewhere inside application logic, use amplitudeUserId instead.
         */
        if (!isLegalPages && amplitudeUserId && amplitudeUserId !== usidQueryParam && abTestData) {
            const newQueryParams = { ...allQueryParams };

            if (flowOuterQueryParam) {
                delete (newQueryParams as any).flowOuter;
            }

            newQueryParams['usid'] = amplitudeUserId;

            navigate(
                {
                    pathname,
                    hash,
                    search: createSearchParams(newQueryParams).toString(),
                },
                { replace: true, preventScrollReset: true }
            );
        }

        /* Send amplitude page navigation event on last update */
        if (amplitudeUserId && amplitudeUserId === usidQueryParam && abTestData) {
            /* Saving AB test data into store */
            if (!sessionAbTestData && abTestData) {
                setSessionAbTestData(abTestData);
            }

            setAmpliIDLoading(false);
        }
    }, [pathname, amplitudeUserId, usidQueryParam, flowOuterQueryParam, gclidQueryParam, abTestData]);

    return <IpBasedAccessControl>{children}</IpBasedAccessControl>;
});
