import { useLayoutEffect, useState } from 'react';
import { fetchAndActivate, getAll, getRemoteConfig, isSupported } from 'firebase/remote-config';
import { app } from 'services/firebase';

export const useAbTestData = () => {
    const [abTestData, setAbTestData] = useState<Record<string, string> | undefined>();
    const [allAbTestFlags, setAllAbTestFlags] = useState<Record<string, string> | undefined>();

    useLayoutEffect(() => {
        (async () => {
            try {
                const remoteConfigIsSupported = await isSupported();

                if (!remoteConfigIsSupported) {
                    throw new Error();
                }

                if (remoteConfigIsSupported) {
                    const remoteConfig = getRemoteConfig(app);
                    remoteConfig.settings.minimumFetchIntervalMillis = 3600000; /* 1 hour */

                    await fetchAndActivate(remoteConfig);
                    const allAbTestFlags = getAll(remoteConfig);

                    const allFlagValues = Object.keys(allAbTestFlags).reduce((acc, key) => {
                        acc[key] = allAbTestFlags[key]['_value'];
                        return acc;
                    }, {});
                    setAllAbTestFlags(allFlagValues);

                    const abTest1ShowPaypal20240307 = allAbTestFlags['ab_test_1_show_paypal_20240307']?.asString();
                    const settingsAmplitudeJSON = allAbTestFlags['settings_amplitude']?.asString();
                    const abPriceIdMyPhIn = allAbTestFlags['ab_price_ID_MY_PH_IN_2024_06_07']?.asString();
                    const abPriceSgJpTh = allAbTestFlags['ab_price_SG_JP_TH_2024_06_07']?.asString();
                    const abApplepay20240814 = allAbTestFlags['ab_applepay_2024_08_14']?.asString();
                    const abPaywall = allAbTestFlags['ab_paywall_2024_10_09']?.asString();

                    setAbTestData({
                        abTest1ShowPaypal20240307,
                        settingsAmplitudeJSON,
                        abPriceIdMyPhIn,
                        abPriceSgJpTh,
                        abApplepay20240814,
                        abPaywall,
                    });
                }
            } catch {
                setAbTestData({});
                console.error('Failed to fetch Firebase remote config');
            }
        })();
    }, []);

    return { abTestData, allAbTestFlags };
};
